import React from 'react';

export default function Download() {

 function downme(p) {
        setTimeout(function(){
           window.location.href = '/thanks'+'?d='+p;
        }, 2000);
  }

  return (

    <section id="greeting" className="about-section-3 text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto ">
            <h2 className="mb-4">Downloads</h2>
          </div>
        </div>
        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-5 justify-content-center">
            <a href="/download/fortonaSans.zip" role="button" onClick={() => downme("fortonasans")} className="btn btn-down btn-primary w-100 p-3 mb-3">
              fortonasans
            </a>

          </div>
        </div>
      </div>
    </section>

  );
}
