import React from 'react';

import Layout from '../components/Layout';

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';

import Download from '../components/Download';

const IndexPage = () => (
<Layout>

  <Header />
<Download />

<div className="covertheugly">
</div>
  <SocialLinks />
  <Footer />
{/*TODO finish footer*/}

</Layout>
);

export default IndexPage;
